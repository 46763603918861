<template>
    <el-scrollbar class="BoothAdvertisingAdd">
<!--        <div class="header">-->
<!--            <el-breadcrumb separator-class="el-icon-arrow-right">-->
<!--                <el-breadcrumb-item :to="{ path: '/admin/operatedata/index'}">运营推广管理</el-breadcrumb-item>-->
<!--                <el-breadcrumb-item>展位广告</el-breadcrumb-item>-->
<!--            </el-breadcrumb>-->
<!--        </div>-->
<!--        <div class="headerStep">-->
<!--            <div class="stepOne">-->
<!--                <span class="num numActive">1</span>-->
<!--                <span class="text">创建展位数据</span>-->
<!--            </div>-->
<!--            <img v-if="stepIndex == 1" class="arrows" src="./../../assets/images/serviceData/xian.png" alt="">-->
<!--            <img v-if="stepIndex > 1" class="arrows" src="./../../assets/images/serviceData/blue.png" alt="">-->
<!--            <div class="stepOne">-->
<!--                <span :class="stepIndex > 1 ? 'num numActive' : 'num'">2</span>-->
<!--                <span class="text">添加展位数据</span>-->
<!--            </div>-->
<!--            <img v-if="stepIndex < 3" class="arrows" src="./../../assets/images/serviceData/xian.png" alt="">-->
<!--            <img v-if="stepIndex > 2" class="arrows" src="./../../assets/images/serviceData/blue.png" alt="">-->
<!--            <div class="stepOne">-->
<!--                <span :class="stepIndex > 2 ? 'num numActive' : 'num'">3</span>-->
<!--                <span class="text">添加竞争数据</span>-->
<!--            </div>-->
<!--            <img v-if="stepIndex < 4" class="arrows" src="./../../assets/images/serviceData/xian.png" alt="">-->
<!--            <img v-if="stepIndex > 3" class="arrows" src="./../../assets/images/serviceData/blue.png" alt="">-->
<!--            <div class="stepOne">-->
<!--                <span :class="stepIndex > 3 ? 'num numActive' : 'num'">4</span>-->
<!--                <span class="text">创建成功</span>-->
<!--            </div>-->
<!--        </div>-->
        <div class="StoreMarketingForm">
            <keep-alive>
                <router-view></router-view>
            </keep-alive>
        </div>
    </el-scrollbar>
</template>

<script>
    export default {
        name: "BoothAdvertisingAdd",
        data(){
            return {
                stepIndex:1
            }
        },
        watch: {
            $route(route){
                if(route.name === 'operateDataBoothAdvertisingTwo'){
                    this.stepIndex = 2
                }
            }
        },
        created() {
            if(this.$route.name === 'operateDataBoothAdvertisingTwo'){
                this.stepIndex = 2
            }
        }
    }
</script>

<style scoped lang="scss">
    .BoothAdvertisingAdd{
      height: 100%;
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
            .el-scrollbar__view {
                padding: 30px;
            }
        }
        .headerStep{
            margin-top: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            .stepOne{
                display: flex;
                flex-direction: column;
                align-items: center;
                .num{
                    line-height: 1;
                    display: inline-block;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    background: #D1D2DB;
                    color: #ffffff;
                    font-size: 18px;
                    font-weight: 500;
                    text-align: center;
                    line-height: 40px;
                }
                .numActive{
                    background: #1122D8;
                }
                .text{
                    line-height: 1;
                    color: #878994;
                    font-size: 14px;
                    font-weight: 500;
                    margin-top: 10px;
                }
            }
            .arrows{
                height: 10px;
                margin-bottom: 20px;
            }
        }
        .StoreMarketingForm{
            /*margin-top: 76px;*/
        }

    }
</style>